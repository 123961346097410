@import "_app";

.appointments-body {
  padding: 22px;
  height: auto;
  min-height: 100%;
  margin-bottom: 100px;
}

.appointment-search {
  width: 18em;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
  margin-top: 4em;
}

.table-button-group > .table-button {
  margin: 0px 1em;
  color: black;
  line-height: 30px;
}

.card-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 1em;
  padding-left: 1em;
  background-color: white;
  height: 100%;
  max-height: 300px;
  cursor: grab;
}

.card-header {
  display: flex;
  justify-content: space-between;
  padding-left: 1em;
  border-left: 1px solid #a58123;
}

.card-date {
  font-weight: bold;
  color: #a58123;
}

.card-status {
  padding-left: 1em;
  font-style: italic;
  font-size: 0.8em;
}

.card-mentor,
.card-mentee,
.card-topic {
  padding-left: 1em;
  margin: 5% 0em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-topic {
  color: #a58123;
  font-size: 0.9em;
}

.modal-body {
  padding: 1em 2em 2em 3em;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.modal-date {
  margin-top: 2em;
  padding-left: 1em;
  border-left: 1px solid #a58123;
  height: fit-content;
  font-weight: bold;
  color: #a58123;
  font-size: 1.3em;
}

.modal-status {
  font-style: italic;
  font-size: 0.8em;
  text-align: end;
  margin-bottom: 0.6em;
}

.modal-info-body {
  display: flex;
  justify-content: space-between;
}

.modal-info-header,
.modal-info-text {
  color: #7a7a7a;
}

.modal-topic {
  color: #a58123;
  margin-top: 1em;
}

.modal-note {
  margin-top: 3em;
  width: 50%;
}
.custom-ant-pagination {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: none;
    transition: border 0.2s;
    cursor: pointer;
  }

  li {
    list-style-type: none;
    display: inline-block;
    min-width: 25px;
    height: 32px;
    margin-inline-end: 8px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 6px;
    outline: 0;
    cursor: pointer;
    user-select: none;
  }

  .ant-pagination-item-active {
    font-weight: 600;
    background-color: #ffffff;
    border-color: #800020;
  }
  .ant-pagination-disabled {
    cursor: not-allowed;
    .ant-pagination-item-link {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.navigation-header {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 48px;
  align-items: center;
  padding: 0 2em;
}

.navigation-sidebar {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}

.language-select-style {
  margin-right: 2em;
  font-weight: bold;
}

// For mobile screens
.mentee-logo {
  float: left;
  width: 50px;
}

.dark-mentee-logo g {
  fill: white;
}
.navigation-footer {
  display: flex;
  justify-content: end;
  position: fixed;
  bottom: 0px;
  right: 10px;
  padding: 5px;
}

@media (min-width: 501px) {
  // For desktop screens
  .mentee-logo {
    height: 90px;
    width: 100%;
    padding: 1em;
  }
}

@import "_app";

.gallery-container {
  display: flex;
  flex-direction: row;
  padding: 1vh;
}

.gallery-filter-container {
  flex: 1;
  margin-right: 1rem;
  background-color: $theme-orange-light;
  padding: 2vh;
  border-radius: 8px;
  height: fit-content;
}

.gallery-filter-header {
  font-size: 3vh;
  font-weight: bold;
}

.gallery-filter-section-title {
  color: $theme-orange-dark;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
}

.gallery-header-name {
  font-size: 35px;
  font-weight: bold;
}

.gallery-header-description {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
}

.gallery-mentor-container {
  flex: 5;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26%, 1fr));
  grid-gap: 1rem;
  height: 100%;
  overflow: hidden;
  margin-bottom: 75px;

  @media only screen and (max-width: 1550px) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}

.gallery-mentor-card {
  background-color: white;
  border: 2px solid $theme-orange;
  border-radius: 8px;
  position: relative;
  height: 27em;
  padding: 20px;
  padding-top: 0px;
}
.gallery-partner-card {
  background-color: white;
  border: 2px solid $theme-orange;
  border-radius: 8px;
  position: relative;
  height: 35em;
  padding: 20px;
  padding-top: 0px;
}

.gallery-mentor-card:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gallery-lesson-types {
  font-style: italic;
  color: rgba(0, 0, 0, 0.45);
  padding-bottom: 10px;
}

.gallery-dot {
  height: 10px;
  width: 10px;
  background-color: $theme-orange;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

.gallery-card-header {
  display: flex;
  padding-top: 10px;
  font-weight: 2rem;
  padding-bottom: 10px;
}

.gallery-links {
  // color: #7a7a7a;
  margin-top: 10px;
  margin-top: 10px;
  text-decoration: none;
}

.gallery-info-section {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.gallery-links:hover {
  text-decoration: none;
  text-decoration: underline;
  color: #7a7a7a;
}

.gallery-header-text {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 75%;
}

.gallery-header-icon {
  padding-right: 5px;
}

.gallery-headers {
  color: $theme-orange-dark;
  margin: 0;
  text-align: left;
}

.mentee-gallery-headers {
  color: $theme-orange-dark;
  margin: 0;
  text-align: left;
}

.gallery-list-items {
  display: block;
  text-align: left;
  padding-left: 27px;
  padding-bottom: 12px;
}

.gallery-card-footer {
  border-top: 3px solid $theme-orange;
  position: absolute;
  bottom: 0px;
  width: 90%;
}

.gallery-button {
  float: right;
  margin-bottom: 15px;
  margin-top: 10px;
}
#filter-button {
  display: none;
}
.loadingIcon {
  margin: 30px 0;
  margin-bottom: 20px;
  padding-top: 10%;
  padding-left: 40%;
  text-align: center;
  border-radius: 4px;
}

@media only screen and (max-width: 640px) {
  .gallery-filter-container {
    margin: 0;
  }
  .mobile-invisible {
    display: none;
  }
  #filter-button {
    margin-top: 2%;
    margin-left: 2%;
    display: grid;
  }
}

@import "_app";

.videos-header {
  display: flex;
  align-items: flex-end;
  height: 130px;
  padding-left: 16px;
}

.videos-header-title {
  font-size: 36px;
  font-weight: bold;
}

.videos-upload-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 8px;
}

.edit-videos {
  background: #e4bb4f;
  color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 25px;
  margin-bottom: 23px;
  font-weight: bold;
}

.video-submit-card {
  border: 0.5px solid black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 36px;
  border-radius: 8px;
}

.video-submit-title {
  padding-left: 16px;
  padding-top: 1em;
  font-size: 20px;
  font-weight: bold;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  height: 40px;
}

.video-submit-input {
  padding: 5px 0px;
}

.filter-card {
  margin-left: 64px;
  width: 33%;
  margin-bottom: 42px;
}

.filters {
  display: flex;
  width: 100%;
}

.videos-container {
  margin-left: 64px;
  margin-right: 35px;
  margin-bottom: 50px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.244974);
  max-width: 900px;
}

.videos-table-title {
  margin-left: 40%;
  display: flex;
  justify-content: space-evenly;
  border-left: solid black;
  border-width: 1px;
  align-items: center;
  padding: 4px;
}

.videos-table-title h1 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
}
.select-box-specializations {
  width: 200px;
}
@media (max-width: 600px) {
  .filter-card {
    width: 100%;
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 10px;
  }
  .filters {
    display: block;
  }
  .select-box-specializations {
    margin-top: 10px;
    width: calc(100% - 65px) !important;
  }

  .videos-table-title {
    display: none;
  }
  .video-row {
    display: block;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    .video-block {
      display: block;
    }
    .video {
      width: 100%;
      padding: 10px;
    }
    .video-description {
      white-space: normal;
      padding: 10px;
    }
    .pin-container {
      width: 50px;
      padding: 10px;
    }
    .video-interactions {
      position: absolute;
      bottom: 10px;
      left: 40px;
      height: auto;
    }
  }
}

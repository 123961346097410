.emailPart {
  position: absolute;
  width: 80%;
  left: 10%;
  right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  top: 210px;
}
.emailPart p {
  width: 141px;
  height: 54px;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 61px;
  color: #ffffff;
}

.emailIn {
  min-width: 522px;
  height: 54px;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #6f6a6a;
  margin-bottom: 35px;
}

.roleGroup {
  position: absolute;
  width: 60%;
  height: 37.76px;
  left: 20%;
  right: 20%;
  top: 305px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  /* or 92% */

  /* Character/Title .85 */

  color: rgba(0, 0, 0, 0.85);
}
.role {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
}
.formsPart {
  position: absolute;
  width: 68%;
  min-height: 858px;
  left: 16%;
  top: 635px;
  right: 16%;

  background: #fffbfb;
  border-radius: 10px;
  padding-bottom: 30px;
}
.trainpart {
  min-height: 500px;
}
.buildpart {
  overflow-x: hidden;
}
.steps {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 331px;
}

.step0 {
  width: 314px;
  height: 244px;
}
.step1 {
  width: 332px;
  height: 257px;
}
.step2 {
  width: 332px;
  height: 257px;
}

.applySubmit2 {
  font-weight: 400;
  font-size: 23px;
  background: #e4bb4f;
  text-align: center;
  color: white;
  cursor: pointer;
  text-align: center;
  width: fit-content;
  font-family: "Roboto";
  font-style: normal;
  width: 500px;
  height: 70px;
  line-height: 70px;
  /* identical to box height */
  color: #ffffff;
  margin-bottom: 20px;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 50px;
}
.testbtn {
  padding: 0 20px;
  height: 27px;
  position: absolute;
  right: 18%;
  bottom: 18%;
  background: #e4bb4f;
  border-radius: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  text-align: center;
}
.error {
  font-weight: 600;
  color: red;
}

.hide {
  display: none;
}
.applymessage {
  position: absolute;
  width: 850px;
  height: 500px;
  left: 0;
  right: 0;
  margin: auto;
  top: 781px;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 82px;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 1200px) {
}
@media (max-width: 600px) {
  .formsPart {
    min-width: auto;
    left: 1%;
    top: 1200px;
    width: 98%;
  }
  .home-text {
    top: 150px;
  }
  .emailPart {
    top: 350px;
  }
  .emailIn {
    min-width: 100%;
  }
  .roleGroup {
    top: 420px;
  }
  .steps {
    display: block;
    text-align: center;
    top: 460px;
  }
  .applymessage {
    top: 1200px;
    width: 100%;
  }
}
@media (min-width: 600px) {
  .steps {
    left: 20%;
    right: 20%;
    width: 60%;
  }
}

@import "_app";

.timeslot {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 3px;
  margin-top: 10px;
}

.date-header {
  float: right;
  display: grid;
  text-align: right;
  padding-right: 10px;
}

.close-icon {
  padding-left: 10px;
  margin-bottom: 10px;
}

.status {
  list-style: none;
  float: left;
}

.add-times {
  margin-top: 10px;
  margin-bottom: 10px;
}
#error {
  display: none;
}

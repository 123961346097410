@import "_app";

.mentor-profile-content-flexbox {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

// TODO: Fix this. It is currently a temp fix for hidden edit
.mentor-profile-contact {
  margin-bottom: 3.5em;
}

.profile-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.mentor-profile-about {
  word-wrap: break-word;
}

.mentor-profile-contact-icon {
  margin-top: 6px;
  margin-right: 2%;
  align-items: center;
}

@media (max-width: 768px) {
  .mentor-profile-contact {
    margin-top: 4px;
  }

  .mentor-profile-button {
    margin-top: 1rem;
  }

  .mentor-profile-heading {
    margin: 0;
  }
}

@media (min-width: 768px) {
  //For desktop

  .mentor-profile-content {
    padding-top: 80px;
    padding-left: 40px;
  }

  .mentor-profile-content-flexbox {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .mentor-profile-info {
    width: 70%;
  }

  .mentor-profile-name {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-weight: bold;
    font-size: 20px;
  }

  .mentor-profile-decorations {
    display: flex;
    align-items: center;
  }

  .mentor-profile-actions {
    display: flex;
    justify-content: space-between;
    margin-right: 1em;
  }

  .mentor-profile-book-appt-btn {
    margin-right: 1em;
  }

  .mentor-lock-symbol {
    margin-left: 0.5em;
  }

  .mentor-lock-symbol svg {
    color: #a58123;
    font-size: 0.9em;
  }

  .yellow-dot {
    color: $yellow-dot-color;
  }

  .mentor-profile-tags-container {
    display: flex;
  }

  .mentor-profile-tag {
    max-width: 25%;
    padding-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .mentor-profile-tag-icon {
    margin-right: 4px;
  }

  .mentor-profile-about {
    width: 90%;
  }

  .mentor-specialization-tag {
    border: 1px solid black;
    border-radius: 13px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 6px;
    margin-right: 6px;
  }

  .mentor-profile-contact {
    border: 1px solid #efefef;
    border-radius: 8px;
    width: 25%;
    height: 30%;
    padding: 1%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.244974);
  }

  .mentor-profile-contact-header {
    font-size: 20px;
    font-weight: bold;
    height: 36px;
  }

  .mentor-profile-contact-icon {
    align-self: flex-start;
    margin-top: 3.5%;
    margin-right: 5%;
  }

  .mentor-profile-contact-edit {
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
    color: $edit-contact-color;
  }

  .mentor-profile-button {
    margin-right: 2vw;
  }

  .mentor-profile-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
  }

  .mentor-profile-editing-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mentor-profile-notifications-container {
    flex: 0.7;
  }

  .mentor-profile-save-container {
    display: flex;
    flex: 0.3;
  }

  .favorite-button-profile {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}

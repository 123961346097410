@import "_app";

.mentee-appointments-page {
  display: flex;
}

.mentee-appts-section {
  width: 70%;
  padding-left: 2em;
  margin-bottom: 2em;
}

.mentee-appts-header {
  margin-top: 4em;
  font-size: large;
  font-weight: bold;
}

.mentee-appts-container {
  margin-top: 2em;
  width: auto;
}

.mentee-appts-overlay-style {
  color: black;
  min-width: 110px;
}

.mentee-appt-card-header {
  font-size: 1.2em;
  padding-left: 1em;
  font-weight: bold;
  color: black;
}

.mentee-appt-card-header a {
  color: black;
  text-decoration: underline;
}

.mentee-appt-card-time {
  margin: 0.25em 0 1em 0;
  padding-left: 1.25em;
  line-height: normal;
}

.mentee-appt-card-topic {
  text-overflow: ellipsis;
}

.status-section {
  float: right;
  display: flex;
  margin-top: -10px;
}

.status-denied,
.status-accepted,
.status-pending {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  align-self: center;
  margin-left: 5px;
}

.status-denied {
  background: #ff4d4f;
}

.status-accepted {
  background: #6fcf97;
}

.status-pending {
  background: #ffdb6f;
}

.mentee-bookmark-add {
  display: flex;
  flex-direction: column;
  padding: 10% 27.5%;
  border-bottom: 0.5px solid $yellow-dot-color;
  align-items: center;
}

.mentee-bookmark-add img {
  margin: 0em 0 1em 0;
}

.mentee-bookmark-add Button {
  height: 3em;
}

.mentee-bookmark-header {
  margin: 1em 1.5em;
  font-size: 1.25em;
  font-weight: bold;
}

.mentee-bookmark-header span,
.bookmark-heart {
  color: $yellow-dot-color;
}

.mentee-bookmark-display {
  margin: 0 1.5em 3em 1.5em;
  padding-top: 4px;
  background: #ffffff;
  border: 0.5px solid #e4bb4f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  min-height: 4em;
}

.mentee-bookmark-card {
  display: flex;
  align-items: center;
  box-shadow: 14px 24px 3px -24px #e4bb4f;
  padding: 10px 5px 10px 5px;
}

.mentee-bookmark-mentor-info {
  width: 100%;
  margin-left: 5px;
  line-height: 1em;
}

.mentee-bookmark-mentor-name {
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
}

.mentee-bookmark-mentor-title {
  font-size: 0.75em;
  color: #727272;
  width: fit-content;
}

.bookmark-heart {
  float: right;
  margin: 0.5em 0.5em 0 0;
}

.bookmark-spin {
  margin-top: 1.5em;
}

.no-favorites-text {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .mentee-appointments-page {
    flex-direction: column;
  }

  .mentee-appts-section {
    width: 100%;
  }

  .status-section {
    float: none;
  }
  .mentee-appt-card-time,
  .mentee-appt-card-header {
    padding-left: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .mentee-bookmark-section {
    margin-left: auto;
  }
}
.interest-modal {
  height: 45vh !important;
  .modal-container {
    height: 100% !important;
  }
}

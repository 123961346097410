//Theme Colors
$theme-orange: #e4bb4f;
$theme-orange-light: #fff7e2;
$theme-orange-dark: #a58123;
$theme-orange-pastel: #fcf6e8;

//Appointments constants
$appointment-card-background-color: #ffecbd;
$appointment-card-header-background: #e4bb4f;
$appointment-background-color: #fcf6e8;
$appointments-date-text-block-color: #a58123;
$appointments-row-border-color: #e4bb4f;
$appointments-description: #7a7a7a;
$appointments-tab-background-color: #ffecbd;
$appointments-tab-color: #a58123;
$appointments-tab-item-selected-background-color: #e4bb4f;
$appointments-tab-item-selected-color: #fff7e2;

// Navigation constants
$nav-header-height: 88px;
$footer-gap: 25px;
$nav-header-color: #fbecc1;
$nav-menu-item-color: White;
$nav-menu-item-hover-background-color: #fcf6ea;
$nav-menu-item-hover-color: black;
$nav-menu-item-selected-color: #fcf6ea;
$profile-caret-color: Gray;
$mentee-logo-color: #fbecc1;
$nav-submenu-color: #e4bb4f;

// Profile constants
$background-color-strip-color: #fcf6e8;
$mentor-profile-heading-color: #828282;
$yellow-dot-color: #e4bb4f;
$specialization-tag-border: #ffecbd;
$edit-contact-color: #a58123;

//Mentee Appointment Modal constants
$mentee-appointment-heading-border-color: #e0e0e0;
$mentee-appointment-text-color: #7a7a7a;
$mentee-appointment-info-border: #cccccc;

// MenteeButton constants
$mentee-button-regular: #e4bb4f;
$mentee-button-dark: #a58123;
$mentee-button-light: #fcf6e8;

// Auth pages constants
$register-link-hover: #ffe5a3;

// Admin Appointments Page
$admin-appointment-body: #fffdf5;
.submit_profile {
  margin: -12px;
}

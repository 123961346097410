.trainingDesc {
  padding: 20px;
  opacity: 70%;
  padding: 12px;
}

.train_list li {
  padding: 33px;
  display: flex;
  flex-direction: column !important;
  margin-bottom: 20px;
}
.chapter {
  padding-left: 5px;
}
.external-link {
  max-width: 90%;
  overflow-wrap: break-word;
}
.new-train-header {
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
}
.new-train-text {
  text-align: center;
  font-family: "Abel";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
}

@import "_app";

.dragdrops {
  padding: 20px;
}

.dragdrop {
  width: 50%;
  float: left;
  padding: 20px;
}

.dragdrop h3 {
  text-align: center;
}

.mentee-video-container {
  width: 100%;
  max-width: 350px;
  align-self: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.244974);
  border-radius: 8px;
  padding: 0.6%;
  margin-top: 10%;
}

.mentee-profile-video-divider {
  border: 1px solid #dadada;
  // margin: 1em -8px;
  width: 100%;
}

.pinned-video-default-preview {
  background: #e0e0e0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.244974);
  border-radius: 8px;
  width: 100%;
  height: 25vh;
}

.video-border {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dadada;
}
